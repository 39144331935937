export interface Chapter {
    id: number;
    title: string;
    paragraphs: (string | string[])[]
}

export function getChapterTitle(chapter: Chapter): string {
    return '<span>Chapter ' + chapter.id + ': ' + chapter.title.toUpperCase() + "</span>";
}

const Chapters: { [id: number]: Chapter } = {
    1: {
        id: 1,
        title: "(Do you exist?)",
        paragraphs: [
            "First, let me say that I do not believe that I am a person who has either been called or chosen, I am simply one who has asked the question, does God exist?",
            "I have in my past had different ones tell me they do not believe I would be a person suitable for writing a book on religion. Well, I could not agree more, but then I have never claimed to be doing so. I never set out to write a book at all. Some years ago, I simply began to make notes about things that were happening in my life. This book is not to tell you what you should or should not do when it comes to religion; it is simply my testimony, it is about what I believe God has done for me.",
            "I had not been born into a religious home, in fact My Mother had believed that religion was very dangerous and that those who became so would sooner or later end up in a home for the mentally ill. One day. a member of a Presbyterian Church that was close to where we had lived at the time, called on her and promised to help get us, her nine children, shoes, and wet weather clothing etc., if she agreed to send us to their Sunday school. I do not remember going more than one time, I do however remember drawing a picture of something made of stones. ( A wall or well. To day I refer to this as being the first brick in the building of my faith.)",
            "After being sent to Sunday school, I wanted to learn more about God, but my family was not at all happy about me wanting to do so.",
            "As my home was not a place to talk about God, I began to listen to religious programs on the radio on Sunday nights, such as Christian Science Heals and The World Tomorrow.",
            "There was Bible Instruction at school, but after I heard a teacher, who in my way of thinking, described God as being a type of Santa, I began to wonder if that is what God was, simply a type of Santa for adults.",
            "One day after I had found a small pet rooster of mine had died, I set out to bury it and as I was doing so, I burst into tears and began to pray out loud, over, and over, <u>Oh God are you true?</u>",
            "Had I that day realized that if God did not exist, then there was no hope for the living or the dead?",
            "I left school at the age of fourteen with hardly the ability to read or write my own name.",
            "I managed to gain employment at a cycle and mower repair center. It was here that I would meet three men who would help me in my search for God. Firstly, there was Paul who was very quiet and kind, he how ever never mentioned God to me.. Then there was Bruce who was very religious. He tried hard to get me to become a believer like him, but I was looking for proof, I simply did not want to become another religious person. Bruce and I had many long, and at times, very heavy debates that I believe achieved very little.",
            "How ever if it were possible today, I would thank him for at least trying.",
            "Then there was Mr. Russell, the owner and manager of the business. He taught me how to use the tools of the trade and to learn about doing repairs correctly. Mr. Russell was also concerned that I did not look after myself as well as I should - I had bad acne and my teeth were also in need of repair.",
            "I loved motor vehicles, especially motor cycles - I cared very little about other things, including my own wellbeing. I had worked at Russell Cycles for several years and then one sad day I learned that Mr. Russell had decided to retire and sell the business, which meant that I was going to have to find another place to work.",
            "On my last day working there, Mr. Russell called me aside and he thanked me for being a good worker. He then told me that he believed I had some bumps ahead in my life and then, to my surprise, suggested that when these times arrived, I should try prayer. I told him that as I did not believe in God, what good would prayer do. He told me that he believed prayer was good for you, whether you believe or not.",
            "It wasn't too long before I was out looking for work. I knew it was never going to be easy to find another place to work as good as Russell Cycles had been for me. I had dreamed of one day becoming a motor cycle mechanic, but with my lack of education I could not see it ever becoming possible.",
            "There was work being offered at the local saw mills and there was employment being offered for those who had a heavy vehicle license, but there was nothing suitable for me. After some time, I began to run out of money - money that I needed to pay for my motor cycle, but more importantly, money to pay my parents' board.",
            "Another Friday arrived, which meant that another week was about to come to an end, and still I had not found employment.",
            "I was home alone and so I decided to take Mr. Russell's advice and try the unthinkable, prayer.",
            "I asked myself what harm could it do and who would ever know. I went to my room and closed the door behind me, I got down on my knees and began to pray. “God, I don't know if you exist, but if you do, can you please help me to find work.” Suddenly, right there and then, our front door bell rang.",
            "On answering the door, I discovered Paul, who I had worked with at Russell Cycles standing there. He asked me, “Are you looking for work?” I could hardly believe what I was hearing. A moment before, I was on my knees praying for help to find work, and now I had a person at our door asking me if I was looking for work. Paul went on to tell me that he was now working as a sales rep and how he had called on a cycle repair center that morning and they had told him they were looking for a mechanic, so he said, “I thought about you”.",
            "Sadly, I could not see that it was anything more than a coincidence; it just had to be. I applied for the position, and I was successful. I soon found that it was a great place to work. The owner manager was a good person, I would go on to learn a great deal working there. He believed it was important to do a repair properly the first time, even if it did take you a little longer to do so.",
            "My life was not too bad now that I was working again, although my skin was not at all good; in fact, it was getting a lot worse. Most of my friends by now had girlfriends and one or two had even married. I blamed my bad skin for my not having a girlfriend; my face was covered in large red itchy pus-filled lumps that caused me a great deal of pain and discomfort, as well as embarrassment. Time passed and it was not too long before my 20th birthday drew near.",
            "Turning 20 was something I was not looking forward to as it would mean that I was no longer a teenager; something I felt I had missed out on because of my bad skin, but it also meant that I had to enlist for the Army. Going into the Army would mean that I wouldn't be able to hide from others just how bad my skin really was, something I had been doing by no longer going to the beach and so on.",
            "On the night of my 20th birthday, I had come home from work feeling as though the whole world was against me. I was in my room later that night thinking about all my problems when I suffered some kind of nervous breakdown or panic attack. I don't know, but I became terrified at what was happening to me; it was as though my body and mind had separated.",
            "I finally managed to talk myself into sleeping, telling myself that things would be better the next day. But things were not better the next day. In fact, they were a lot worse, as I was now afraid to think of my past or my future. ",
            "If was Good Friday, 16 April 1965, a day that would turn out to be a day I will never forget.",
            "To try and keep my mind off my problems, I spent a good part of the day making a small model building out of cardboard.",
            "During the day I noticed in the TV Guide that there was to be a program on television that night called The Living Christ. As I was going to be home by myself, I decided to watch it. Although the program touched on the Shroud of Turin, it was largely about how crucifixions were believed to have been carried out in the time of Christ. It was believed that these being crucified would have their wrists nailed to the cross piece and not their hands as so commonly portrayed in art. They would then be made to walk up a ladder backwards until the cross piece fitted into place.",
            "Suddenly I found myself on my knees gasping for breath in the middle of the floor; I believed. I believed in God. For a short time, I felt as though my head was full of mud and then when it cleared, I went to bed and slept like a new-born baby. The next day my life was completely different. I had a new outlook on life and a sense of peace and hope like never before. I began to remember things from my past such as Sunday School and my prayer as a child, Oh God, are you true? ",
            "I also began at this time to have vivid dreams of air crashes. I had no idea if they had meanings or not, but then one night I watched a program on television about a woman who was badly hurt in an aircraft crash and she blamed God for her injuries. Why was this, I asked myself. Had God designed and built the aircraft, had He sold her the ticket to be on board, was He the pilot? If not, why should He be blamed. ",
            "Why do we often describe bad things as being Acts of God?"
        ],
    },
    2: {
        id: 2,
        title: "CYCLE MECHANIC TO <u>MOTOR</u> CYCLE MECHANIC",
        paragraphs: [
            "Now that I was a believer, I was quite convinced that nothing was going to upset me the way things had in the past, but I was wrong.",
            "One day my youngest brother told me he had been talking to the manager of Merv Scragg Motor Cycles who told him they were wondering if I would be interested in going to work for them. I could not quite believe what I was hearing. He must not have heard them correctly and why would they be interested in me.",
            "As Muirhead Cycles were only a block away from Merv Scragg's Motor Cycles, this meant I was able to visit them quite regularly as I loved to see what new models had arrived.",
            "I went and talked to them and it was true - they were interested in having me work for them. I asked them if I could have a little time to consider their offer, as there was a lot I had to think about.",
            "It had been my dream to work on motor cycles but with my lack of meaningful education I simply could not see it being possible. Was I going to be able to read and understand the workshop manuals, measurements and so on?",
            "But there was something even more that I had to consider. Now that I was a believer, I had no trouble in believing my prayer for work that day had been answered, so could I now just turn my back on this. What if the new position did not work out, could I get down and pray for help again to find work? What if I were to be asked what happened to my previous place of work, why should I be helped again? I returned to Merv Scragg's and thanked them for offering me the position, but I felt I had no choice but to turn them down. ",
            "I had now worked at Muirheads for several months and I cannot ever remember having a disagreement with Mr. Muirhead, I had respected him so much, one Friday he asked me to put a side a repair that I was working on and asked me to start another as apparently it was more urgent. After starting on this repair, I was once again asked to put it aside as another had arrived that was considered to be even more important, I was not happy about having to put repairs aside to start on another, I had pointed this out to Mr. Muirhead, to my surprise he told me if I was not happy to do this, I should leave, so I did, I grabbed my coat and I headed for home.",
            "By the time I had arrived home I was really upset about what had just happened, I was in tears.",
            "My Mother asked me what was wrong, I told her that I believe God had, had me sacked from Muirheads, the truth is I simply did not know what had happened but I was quite certain that I had done something wrong. My Mother reminded me how she had warned me about becoming religious. About how it would drive me insane.",
            "I could not bring myself to try prayer for another position, another place to work.",
            "After a day or two I decide to go out looking for work, as I had walked through town that day I had walked past the motor cycle shop that had offered me employment. I noticed that in the window they had a gorgeous sports motor cycle and I just had to stop and have a look at it, just then the manager appeared and he asked me what I was up to, I replied that I was not up to anything as I had been sacked from the bike shop, I had not been really sacked, but I did not want anyone other than myself to take the blame for what had happened. To my surprise he then told me that he could not see any reason for me not going to work for them. (They had employed another person, but he had not worked out for them.)",
            "I soon found that I was able to understand how to do what I had needed to do and found that I was able to learn what I had needed to. One day after working there for a few weeks, I was on my knees working on a motor cycle when I suddenly realized that this is where I was meant to be."
        ]
    },
3: {
    id: 3,
        title: "THE MILITARY",
            paragraphs: [
                "It was not too long before I was called up for the Army. This was something I had not looked forward to at all and I believe it was only because I was now a believer that I was able to cope with this.",
                "I suffered badly from home sickness and found all physical things hard to deal with. In time I would discover that I had been born with a heart defect and this was most likely the cause of this. I was given a machine gun to look after; this was something I didn't mind as it sounded like a motor cycle without an exhaust.",
                "There was a person there who prayed before his meals, before he turned into bed, and he did this in front of the other soldiers. I wondered whether I should be doing the same. Then one day he received a 'Dear John' letter and he changed completely - he began to smoke and use bad language.",
                "I tried to be honest in all that I did and I became known for this. My back pack was the only one that was not searched before we went out on survival camps. (No extra food, ext.)",
                "In time, my three and a half months were up and it was time for me to return home and the motor cycle shop.",
            ]
},
4: {
    id: 4,
        title: "THINGS THAT HURT",
            paragraphs: [
                "Although I now believed in God and had the joy that came with knowing this, I had no-one to talk with about my beliefs; my family were certainly not interested in listening to me. Then one night while I was up town, I had an unusual and very painful experience.",
                "I had just said hello to a group of girls that I would see around town from time to time. When one of them made a derogatory remark about me, I decided to never speak to them again, after this and suddenly I felt as though the Lord Himself had scolded me.",
                "I immediately began to pray that I would never have to experience this very painful feeling again. What had I done that was so wrong? I just didn't know.",
                "Over the next few days, I spent a considerable amount of time trying to find an answer to what had happened that night and then it came to me. I had allowed one person to turn me against speaking to the others. There was one person in my family who was particularly angry about my being a believer and she had made it hard for me to speak to the others.",
                "One day I was telling a workmate about something when he called me a liar and he also told me that he didn't believe anything I had said. I felt as though the painful experience was about to return and I began to pray that it wouldn't. I had not been telling lies. The workmate was just unhappy with the boss getting me to assemble all the new cycles when he had wanted to do this him self, so he was trying to hurt my feelings, in just the same way as my family had by not believing me.",
                "The next thing that really hurt me was not being helped with my skin problem. I had prayed about this for some time. If lepers mentioned in the Bible could be healed, then why couldn't I be?",
                "I read about how a person had wanted to become a merchant sailor but things all ways appeared to stop him from doing so. He eventually decided that the Lord wanted him to do other things, so he became a preacher and a good one at that.",
                "Sadly, I began to believe that I was not meant to get married, which made me very unhappy as I so badly wanted to have a wife. I became more and more disheartened about not being helped and eventually I got quite angry. I was very wrong to think like this, in time I would understand.",
                "(I was 20 years old; my wife to be was only 7 years old at this time.)",
                "The Good News Bible had not long been released and I found it was such a good book to read.",
                "I read about the rich young man who asked what he must do to gain eternal life and was told that he must not commit murder or adultery, he was not to steal, lie or cheat, and he was to honor his father and mother. The young man replied that he had always obeyed these commandments and at this, he was told that if he wanted to be perfect, he must sell all he owned and give the money to the poor. On hearing this, the young man was very sad.",
                "For whatever reason, after reading this, I began to wonder if I was ever going to be able to get anyone to become a Christian if they were going to have to sell all they owned. I never stopped believed in God but with my family not willing to listen, with not being helped to get a girlfriend, and now believing that you had to sell all you owned, I put my faith aside.",
                "(Today I believe this was about me being too fond of something other than money.)",
            ]
},
5: {
    id: 5,
        title: "THE WHITE CURTAINS",
            paragraphs: [
                "Several years passed and by now I had given up all hope that I would ever marry.",
                "My skin had improved by now, as I discovered that by applying raw disinfectant to it after I shaved, it would burn like bad sunburn and after a week, I was able to peel layers of my skin off, this helped a great deal.",
                "I became more and more involved with motor cycle sports. I was working with an apprentice motor cycle mechanic one day when he asked me what my favorite motor cycle was. I replied that it would have to be a big Harley Davidson such as an Electra-Glide. To my surprise, the apprentice told me he knew where there was one parked up. He said it was in the valley, not too far out of town.",
                "He told me that it had a 1200cc motor and that it had a reverse gear.",
                "On the next weekend I decided to go and have a look for myself. It turned out to belong to an old man who was well known for owning classic motor cycles such as the Manx Norton. The old motor cycle enthusiast and I soon began to talk about our experiences with motor cycles. I noticed he had quite a large selection of motor cycle magazines strewn around his home. As we talked, I thought about how I could end up like him, a collection of motor cycles and motor cycle magazines.",
                "A mother and daughter Jehovah Witnesses arrived to visit the old man, as they had apparently done from time to time. We talked among ourselves and even told one or two jokes. I could not help but notice how cute the daughter was; she was very petite with ash blond hair. I had seen her around town from time to time and knew she had two brothers who were into motor cycles and they were customers of where I worked.",
                "Over the next few days, I could not stop thinking about the girl even though I knew she was way too good for me. I just wanted to see her again and I began to look at ways I might do this.",
                "I then thought about the fact that she had two brothers who were motor cycle enthusiasts and that they were customers where I worked.",
                "I decided that I would offer to bring a high-performance motor cycle around on a Saturday for them to test ride - I was hoping that the girl would be there. To my delight, her brothers agreed to take turns at test riding the motor cycle and even more to my delight, one of them ended up buying it. After the test ride, I was invited into their very tidy house. I noticed there was a white lace curtain separating their dining room from their living room. As I noticed this, I suddenly felt bad and came very close to bursting into tears. I was about to leave when the feeling began to ease, so I decided to stay. I had no idea what happened that day, but in time I believed that I would.",
                "After that day, I began to spend time with her brothers. We would go to motor cycle events together at the weekends and in time I began to attend meetings at their church. I would see the girl from time to time, but she appeared to show no interest in me at all.",
                "I began to study the Bible with her father. Although I did not agree with everything they believed, I did agree with most of what they did.",
                "(For one thing, Jehovah Witnesses do not believe that Jesus died on a cross. They believed that he died on a stake, I believe that he had died on a cross made up of two parts. A stake with a cross piece.)(There were Jehovah Witnesses who had believed that the world as we had known it was going to end in 1975 as they had that it was going to end in 1914, , I did not except this.)",
                "I had given up on ever having the girl as being anything more than a friend. Then one day, to my surprise, I received a phone call from her mother. She asked me if I would mind if her daughter came with her brothers to the next movie we attended. I could hardly believe my ears. Would I mind if the girl I was in love with came to the movies with us! When she arrived that night, she walked over to me and I took her hand and she became my girl friend. I now had a girlfriend and a gorgeous one at that.",
                "Although I had originally said that I was not willing to go out with them on their door-to-door ministry, I eventually did.",
                "There were a couple of things that happened that I will most likely never forget. One day while going from door to door, I came across a sign on a gate saying there were guard dogs on duty. I had come across signs like this before and usually these signs were there just to try and discourage anyone from entering the property. I decided to go and see if anyone was home and after wandering up a long winding path I arrived at the front door of a big old wooden house. I knocked on the door but there was no answer. I was about to turn to leave when I heard a noise behind me, and as I turned, I saw two Doberman Pincher dogs coming towards me at high speed. It wasn't too hard to see that they were real guard dogs, as both their ears and tails had been clipped. Just as the dogs were about to collide with me, I heard a very soft voice that appeared to come out of nowhere. I have no idea what the voice said but suddenly the dogs appeared to freeze in their tracks, they looked at one another and then turned and quietly walked back up the path where they had come from.",
                "Another time I was out going from door to door. As a group we had been warned not to go onto a certain property as the person living there had threatened to hurt the next Witness that went on to his property. For whatever reason, the next thing I knew I was knocking on his door. I introduced myself and he then told me he felt very sorry for me. I asked him why and he told me it was because I did not accept the Trinity doctrine. I opened the Bible that I was carrying with both the Old and New Testaments. I pointed out that I believed the Old Testament represented Jehovah and the New Testament represented Jesus. I then closed it and pointed out that it was now one Book. At this, he suddenly changed and he then invited me back to his house to have a meal with him, and I accepted his offer.",
                "After some time, it became obvious that the girl loved me as much as I loved her, so in time I asked her to marry me and she accepted my proposal.",
                "After we got engage I began to feel that I had paid a deposit on an expensive item that I simply could not afford.",
                "Over the next few weeks, I began to tell her and her family about how I had originally become a believer, (on 16 April 1965) and about my book. They were not at all happy about my book, as they believed all the books that needed to be written were being produced by the Watchtower Bible and Tract Society. Things from my past began to surface, believing that you had to sell all you owned, but even more concerning was the belief that I was not to marry. I began to argue about other things that I was not happy with and soon it was reported to the Elders. In time they recommended the girl should not marry me, so she returned the engagement ring. We had only a month before we were meant to marry - I was broken hearted.",
                "(To day I realize that she was simply not the one I was meant to marry, I would eventually marry a nurse who would no doubt help keep me a live to write my testimony.)",
                "I asked the Elder who had recommended the girl not to marry me, if he accepted that I believed in God. He replied that he did but that I had put the cart before the horse, meaning that he believed the only way to God was through the Jehovah Witnesses. I believed the only real way to God is through Jesus. He accused me of putting the cart before the horse, but he would fall from his high horse. He was a good speaker and musician; it was said that he could give a sermon for several hours without looking at any notes. In time I discovered that he had fallen out with the Witnesses, so I decided to track him down and interview him. To my surprise, he told me he no longer believed in the Jehovah Witnesses or in God. ( I have been told that he eventually became an alcoholic.)",
                "I stayed in touch with the girl's family after we separated. I was visiting one day when I noticed that her father was looking through a collection of small hard-covered books that he had on his table. I asked him what the books were and he told me they were Jehovah Witness year books. I asked him if he had one from 1965 and to my delight he did. I quickly looked up 16 April to see if there was anything special about that day and to my surprise, there was. On that day, Christians had celebrated Good Friday and Jehovah Witnesses had observed the memorial. There was a full moon and it was Nisan 14, Passover Eve. I closed the book and handed it back to him without saying a word.",
                "( According to the best estimates of biblical scholars and historical evidence, Jesus died on Friday, April the 3rd, AD 33 at approximately 3 PM a few hours before the beginning Passover day and the Sabbath. This is the date in the Julian calendar, which was introduced in 45 BC and follows the convention that historical dates adhere to the calenda used at the time. (Christianity , com.)",
                "( So, it is believed that he had died in April, on a Friday, a Sabath eve and there was a full moon that night.",
                "I became a believer in April, on a Friday, Sabath eve, and there was a full moon that night.)",
                "On that day, Christians had observed Good Friday, the Jehovah Witnesses had observed the memorial. The Jews had observed the Sabbath eve. Nissan 14.) Is this simply all a coincidence; I will leave that up to you.)",
                "easter.png",
                ["It is believe that Jesus died in, on a Friday,", "I became a believer in April 1965, on a Friday.", "April the 3rd, AD 33 Sabath Eve", "It was Good Friday.", "There was a full moon that night.", "There was a full moon that night."],
                "( It was Sabath Eve for the Jewish people and it was the memorial for Jehovah Witnesse.)"
            ]
},
6: {
    id: 6,
        title: "THE PROMISED LAND",
            paragraphs: [
                "One day a young lady came into the motor cycle center wanting urgent repairs to be carried out on her motor cycle, as she had a Salvation Army conference in Auckland she hoped to attend. We were busy at this time so I asked her to call back later that day and I would then try and get the repairs done for. When she returned, I jokingly made the comment that if I did get the repairs done, she would have to marry me. We worked together on her motor cycle.",
                "I really liked her and I felt so at home with her. Not too long after this, I received an invitation to have a meal with her and it wasn't too long before we were dating. Within a short time, we were engaged and were making plans to marry. I realized by this time that I had been wrong to believe I was not to marry, and I had turned down an opportunity to marry in the same way I had turned down the opportunity to be a motor cycle mechanic.",
                "After we married, we purchased a large caravan to live in, as it was still my ambition to eventually move to Auckland. Her parents were separated. Her father had not attended our wedding but he did come up north to meet us after we had married. He was a scruffy little man who owned a farm let on the West Coast of the South Island. I was saddened when he suggested that if we were to move down south, he might give us a piece of land. He was quite certain that my wife had wanted to move down south to be with her dad.",
                "I really wanted to go and live in Auckland but I made an agreement that if we could find work down south, I would look at moving down there and we put a time limit on this suggestion. The time ran out and we had not managed to find work, so I went to bed that night believing that we were not going to get work down south. But early next morning I received a phone call from a motor cycle dealer in Hokitika offering me employment. Even though the time had expired, it wasn't too long before we were making plans to move. We purchased an old truck to tow our caravan and we set out for our new home.",
                "When we arrived, it soon became obvious it was not where I wanted to be. The father-in-law owned around 28 heavy horses, several cattle and several sheep and goats. I had no experience with large animals at all. It was not too long before my wife found work as a district nurse in Hokitika and we managed to get a hospital house to live in. When we first met her boss, she said welcome to Israel. Apparently, she had friends living there and she had pointed out it was very much the same size as the Coast.",
                "For the most part I enjoyed working in Hokitika. It was a lot different than working up north. For one thing, the customers did not have the money like the ones up north did. In time we bought a house in Runanga, a small town just out of Greymouth and while there, we had our first child.",
                "For a few months, I commuted from Runanga to Hokitika and one morning when doing so, I had just turned south heading to Hokitika when I noticed an agricultural aircraft coming towards me, on the far side of the road, at about the height of the power lines. As I got closer to it, I was shocked to realize that it was not moving, it was as though it was parked in the air. For whatever reason, I wondered why the pilot did not climb out and lift the bonnet to try and find out why it had stopped. As I was riding a motor cycle at the time, I quickly checked the road ahead but when I looked back it was gone. On arriving at work that morning, I told my workmates what I had seen.",
                "We had a visitor that morning who was a local pilot. He asked me to describe the aircraft to him and after I had, he told me that he knew the aircraft and the pilot. He then told me the pilot would never crash because he was such a good pilot. He even went on to say that the pilot was so good, he could even fly the aircraft backwards in the right conditions. I never said I believed he was going to crash. A short time later I was asked to go to the airport to look at a microlight that would not keep running and it didn't take me long to figure out what the problem was with it. As I turned to leave, I noticed the aircraft I had seen that morning and for whatever reason, it frightened me. I came to work one Monday morning to be told that the plane had crashed and the pilot had been killed. In time it was found that it had been a freak accident, and that neither pilot error nor mechanical failure caused it to crash. Today, I believe I saw a vision that day.",
            ]
},
7: {
    id: 7,
        title: "INTO THE WILDERNESS",
            paragraphs: [
                "From time to time, my wife relieved the rural district nurse in South Westland and in time, she gained a permanent position there. We decided to move to South Westland where my wife would work and I would stay home and look after our wee boy. We moved into a hospital house that was near the beach. It was not too long before I began to believe that it was a mistake to move to South Westland. I simply had not realized how involved my wife would be with drownings, road accidents and helicopter crashes. The sandflies and mosquitos did not help. Most who lived there were involved in deer capture, fishing or in the season, white baiting. My wife was away a lot on ambulance callouts and so on. I didn't like being left alone as much as I was. It certainly was not where I had wanted to be - it was not Auckland.",
                "One day, out of the blue, we were approached by a church group from Wanaka, who asked if we would consider being an outpost for them. They were hoping we would consider having a meeting in our house occasionally. I was a closet Christian and not too keen on the idea to start with and then I became quite convinced that no-one would come along anyway. But I was wrong and in no time the group began to grow, with locals who I had not expected to join our group.",
                "I was approached about helping to find accommodation for a bus load of Christians who were heading our way; they were relay running. Once again, I wasn't too keen on the idea of getting involved. Then I thought I might simply be able to pass this on to other believers in the area but that idea didn't work out. I eventually approached the local motor camp and they told me they could use the main hall to sleep in and it would cost $200 for the night. I asked my wife if we could pay for their accommodation and she agreed to do this. I remember the bus arriving at our house and those who were on board were getting off; it was as though the Lord Himself had arrived with them. Later that night we had a visitor, a local fisherman, who brought a tub of fish that he said was $200 worth. Apparently, his freezer had stopped working, so he decided to give the fish away.",
                "A new family arrived in town and they too joined our group. Initially I was happy about this but it wasn't too long before they began to make it clear they were not happy with my wife and I, and they wanted to make changes. He was convinced he had been sent to the area to convert us to his way of thinking. He carried a Bible with him and if you didn't agree with him, he would tell you that you were going to hell. I didn't like this type of preaching at all. In time, my wife and I travelled out to visit the church he had come from, and the minister told us they had prayed for him to be improved or removed.",
                "Eventually the church group simply got too big for our house, so it was moved to the local town hall. The meetings were largely organized by the Wanaka group. I was more than happy with this, as I was very shy and did not see myself as being anything more than a person who looked for others to join us.",
                "There was an animal ground shooter in the area who told me he had been hoping to shoot a deer or two, when he noticed a helicopter circling around him and he wondered what they were up to. He was speaking to them later and asked them what they had been up to. The pilot told him there were a couple of nice deer close to where he had been standing. He then told me that those in helicopters were able to see things from above, those on the ground were not able to see.",
                "This brings me to one of the best things to happen to me while living in South Westland. The house we lived in backed on to the beach, so I would often gather drift wood for our fire. Sometimes the wood was just simply too big for me to drag home to be cut up.",
                "My wife came home from work one day and told me she had found an old ute that was for sale. It was crash damaged but still drivable, and she wondered if it would be suitable for getting wood off the beach. We purchased it and I then began to look at making it look a bit better. The front mud guards and engine bonnet were very badly damaged and needed to be replaced. I didn't consider it would be worth buying new parts for it, so I went and had a look at the local dump where there were several old vehicles dumped. I found an old car that had parts I could use and with a little cutting and welding, the ute began to look quite acceptable.",
                "ute.png",
                "One other thing the ute would need was dual rear wheels, as this would make it a lot better on the sand. The Ute had new tires with the rear ones having heavy mud grip treads. The spare wheel matched the rear wheels, so this meant I had three tires with the same tread pattern and 'as new' condition. I simply could not afford to buy a new wheel, so I hoped I could find one that would be near enough to do what was required. I decided to go back to the dump and have a look for one that might do. To my surprise and delight, there was a when there that was exactly what I needed. It was the right make, the right model, it had the same make of tire with the same tread, and it was in the same 'as new' condition; it was even the same colour. I could not quite believe my luck. It was exactly what I needed; it couldn't be more perfect.",
                "Then something strange began to happen. It was as though I had a voice telling me the wheel was not mine. I didn't hear a voice but it was as though I had. I began to argue that I had found the wheel at the dump in the same way I had found the other parts and so why was I not told they were also not mine? But the warning continued and so, in the end, I began to ask what I should do if it was not mine. Should I return it to the dump, but if so, what good would that do? The Bible tells of (those who have eyes but cannot see and having ears but not being able to hear or understand. It appeared that I had ears but I did not understand.)",
                "I knew there was a couple in the area who owned a Ute like ours, so I decided to go and ask them if they had their spare wheel just in case they had lost it. We had an old man who often came to visit us. He knew of the couple and he recommended that I stay away from them as he said I was liable to get punched in my face, and that they were not nice people, especially the wife. (I found out later that our old friend had gone around to their place while he was drunk and the wife had asked him to leave.)",
                "I decided to go and ask them if they had lost the wheel. I knocked on their door and the husband answered. I asked him if he had lost a wheel and he replied that his was on the back of his ute, but when he looked, he discovered it was not there. Then he remembered that he had recently dumped rubbish and had placed the wheel on the ground while unloading the rubbish but then drove away, forgetting the wheel. He told me he could hardly believe how honest I was but I did not tell him I had been told that it was not mine to keep. He then asked me if I was a member of the church group and I replied that I was. I told him we were having a meeting on Sunday night and that we had a guest speaker who was a missionary minister from Papua New Guinea who was also going to have a display of mounted butterflies on show.",
                "To my delight, he turned up at the meeting although he didn't have his wife with him. He told me that she was sitting out in their ute as she did not like religion. I asked him if I could go out and talk to her, and he said he didn't mind if I did. I asked her if she wanted to come in, as she was welcome if she did. She told me that she did not like religion. I told her that I was not religious but that I did believe in God. As I turned to walk away, I heard the vehicle door open and she followed me in to the hall. From that moment on, the couple became loyal members of the group. Today I believe that the spare wheel was used by the Lord to get this couple to join the group - they had been seen from above.",
                "I received a phone call one day from a person in the area who had a problem with sheep that had managed to get themselves into a paddock with blackberry and gorse bushes. It was a hot day and some had already died from the heat. Many were trapped deep into the thorn bushes and they were not at all easy to pull out. I remember getting one or two out that came out clean and then they ran up a nearby hill towards the sun. Others we pulled out also headed up the hill, but many of them were dragging different amounts of thorn bushes with them. Then sadly, there were those we pulled out that simply turned and heading back into the thorn bushes. We again pulled them out only to have them head back into the bushes where they were, no doubt, going to die.",
                "After we had finished that day, I suddenly felt the Lord was asking me to become a shepherd. I looked down and I could see that my clothing was covered in sheep poo and blood, my arms were also bleeding. I thought, I cannot be a shepherd, Lord; there must be so many others out there that could do a far better job than me. I was telling a friend of ours about trying to rescue the sheep and how sad I was about those that had returned to the thorn bushes. He told me that the sheep that had returned to the thorn bushes reminded him of a sister of his who had become a prostitute in the city to pay for her drugs. He, along with the rest of his family, managed to get her to come home and even got her to attend meetings at their church, only to have her disappear one day and they discovered her back working on the streets.",
                "I received another call from a farmer who told me he had a mob of sheep on a small island in the Haast River and he learned there was heavy rain on its way, so he was going to have to get them back to his farm before the water began to rise and put them at risk of drowning. I drove to his farm and discovered that he intended to use a tractor and horse float to go out and rescue the sheep. The island was not too far out and the water, at this time, was not too deep to get out to the sheep. When we arrived on the island, I discovered that the sheep were quite big animals and rather strong willed. I asked how he was going to get the sheep on to the horse float and he replied that he was going to use persuasion to do this.",
                "After looking around, he found a length of drift wood and began to hit the sheep around their heads; in no time there was blood coming from their ears and noses. I took the stick off him and then had to physically drag each of the sheep, one by one, on to the horse float. I don't remember how many trips we did that day to get all the sheep ashore to safety. I do, however, remember that it was very misty and I recall thinking that the horse float with its rear door open and the ramp down looked to me like Noah's Ark.",
                "I have given that day a lot of thought. I wondered just how much that farmer really cared about those poor sheep or did he just see them as a commodity. Had he only worried about how much money he would lose out on. I have read about the cruelty that the inquisition had inflicted on those who had not wanted to be their sheep. To my way of thinking, these people were like that farmer, they had simply not been good shepherds.",
                "(The Inquisition was a group of institutions within the Catholic Church whose aim was to combat heresy, conducting trials of suspected heretics. https://en.wikipedia.org/wiki/Inquisition.)",
                "Our church group now had a mixture of people attending the meetings and many of them were from different denominations. I had by now learned to tolerate the new preacher in town but I had not accepted his hard-nosed preaching. One day I learned that he had been asked by the visiting minister to start playing a bigger part in the meetings, opening with prayer and so on. I was very annoyed to hear this, so I got out of my seat and went to sit at the back of the hall. I prayed, “Lord, if he is going to start taking over the meetings, then I am going to just sit up the back and not be part of this. I believe that my wife and I try hard to gather your sheep, but this man is like a dog that scatters them as quickly as we bring them in.”",
                "Suddenly there was a loud bang as though someone had fired a rifle shot among us. When I looked around, I could see some of the members holding their hands over their ears. The next thing I know is the new preacher came to the back where I was sitting and began to apologize for his behaviour. For whatever reason, he was now sorry for working against us and not with us. He even offered to leave the area if I wanted him to. From that day on, we became friends and we had no more trouble. Was the unruly dog shot?",
                "(Although I had not wanted to move to South Westland, it eventually turned out to be one of the best things ever, as it was here that I would begin to learn about computers. Learning to use a computer has made it so much easier to write this book.)",
                "We left South Westland and headed to Central Otago where we had another child, and then we eventually moved to Stewart Island. After Stewart Island we moved to Tokanui on the South Island east coast. My wife carried on working and I continued to be a stay-at-home Dad. We had lost a lot of money by moving around though and did not have a lot to play with.",
                "One day I was working at home when I felt that I was being asked to move back to the West Coast. I talked to my wife and she then rang the hospital in Greymouth and asked if they had any positions available. In a few days' time she received a phone call offering her a position. By then I knew that my wife's father had given the land he had promised us to one of her brothers. I was broken-hearted about this, as I had given up moving to Auckland for this reason, but still I was alive with a wife and two children.",
                "We were going to need money to move back to the West Coast, as we were going to have to hire a furniture removal truck and so on. We needed $4,000 and this was money we simply did not have. I wondered about trying to sell our old caravan and although we really needed it to help us move, I decided maybe it was worth a try. I began to hear a voice telling me it would be down to or through the wire, but I could not understand what that really meant. Was it something electrical? I knew the caravan had a faulty switch, so I replaced it. Time began to run out, so I decided to phone an old friend who had offered to help me in the past. I was so pleased when he agreed to lend me the money as this made it possible for us to move back to the West Coast. I had no idea how we were going to pay back the money we had borrowed.",
                "One of the first things my wife did was to get us on to the electoral roll; she only had a few days to do this. Not too long after this, we were pleasantly surprised to learn that the local power company had decided to pay out a bonus of $2,000 to all those who were on the roll. Between my wife and me, we had the $4,000 we needed to pay back our friend.",
                "I thanked God for this money that we had so badly needed.",
            ]
},
8: {
    id: 8,
        title: "THE STATION WAGON",
            paragraphs: [
                "For the most part, we really enjoyed operating the small Souvenir & Gift Shop on the West Coast but then one day, sadly, we learned that our landlord had decided to relinquish ownership of the building and this meant that we were going to have to find another property that we could live and work from. We began to look at real estate and realized it was not going to be easy to find another place as good as this property had been for us. This was in the days before the Christchurch earthquakes and the West Coast mining disaster, so things were quite busy currently and there weren't a lot of properties for rent or lease.",
                "We needed a place we could afford, a place with enough room for our household goods plus our shop stock and plant. We also needed a place that we could have our pets - three cats and a small dog. Another factor was that I was on the waiting list for heart surgery, which I had been told would take place in six to eight weeks. It soon became clear that there was nothing suitable for our needs and I began to become quite convinced that time would run out before we would have to move.",
                "My wife and I were attending Sunday meetings at The Salvation Army at this time and every so often we would travel over to Christchurch to attend what we called a Sally Rally, (a gathering of the Canterbury Salvation Army people.) As we were travelling over to one of these events and passing through Otira, I noticed a station wagon parked off to the far side, with one end in need of repairs while the other end was okay. Although I could not be 100% certain, I was convinced that it was European. As I drove past, I suddenly noticed that it was a very vivid blue as though it had been coloured with a marker pen. Over the next few days, I began to wonder about that station wagon at Otira - I just could not get it out of my head. I began to believe that it had meaning for me, but what it was, I did not know.",
                "The next time I attended a Sally men's group meeting I decided to raise the subject of the station wagon. To my surprise, I was told by another member there, that he believed it was red and not blue. And that it was Japanese, not European. Although I could not be certain it was European, I was sure that it was blue. I asked others who had travelled over to Christchurch that day, but most could not remember too much about the station wagon. One of them said he also believed it was European.",
                "An associate of ours approached us and told us that he too was looking for a house to rent, but he only needed a small house for himself. We looked on the computer for real estate that was available on the West Coast and discovered there was a small blue house at Kumara for rent. We decided to phone up the owners and discovered it was in fact at Otira and not at Kumara as advertised. This was too far out of town for our friend but I decided that I would drive out and have a look at the place. As soon as I arrived at the front gate of the small blue house, I decided it was the place for us.",
                "(If I had turned and looked over the road I would have seen the station wagon.)", "It had outer buildings where we could store our gear and it had been advertised as being pet friendly. After I had spoken with my wife, we soon signed a tenancy agreement and began to move to Otira.",
                "There was another old house on the property that needed repairs but it was more than suitable for us to use as storage. It turned out to be a great place to live. I loved the native bush, the native birds, and the mountains and night skies were spectacular. I simply loved living in Otira. It was a very quiet place to live, apart from the trains that passed through on their way to Christchurch and back. I loved to walk our small dog and it was while I was on my way home one day that I noticed there was a sign pointing to the street where we lived.",
                "sign.jpg",
                "There was something about this sign that caught my eye - something about the word 'station' and when I turned to continue my way home, I noticed a line of coal wagons.",
                "station.png",
                "So, there was the answer to what the station wagon had meant - station and wagon.",
                "<div class='card'><div class='card-body'><p class='card-text'>A <b>station wagon</b>, also called an <b>estate</b> car, <b>estate</b>, or wagon, is a car body style which has a two-box design, a large cargo area and a rear tailgate that is hinged to open for access to the cargo area. Wikipedia</div></div></div>",
                "Notice that a Station wagon is a two-box design and is called an Estate in Europe. When I looked up station wagon on Wikipedia, I noticed there was Real Estate, Estate Wagon, and Housing Estate. We had just deal with Real Estate and now Estate Wagon, so I decided to look up Housing Estate. To my ..amazement, in my opinion, it described the Otira township to a 'T. ",
                "<div class='card'><div class='card-body'><p class='card-text'>A <b>housing estate</b> is a group of homes and other buildings built together as a single development. The exact form may vary from country to country. Accordingly, a housing estate is usually built by a single contractor, with only a few styles of house or building design, so they tend to be uniform in appearance. Wikipedia</div></div></div>",
                "Notice that Estate is usually built by a single contractor with only a few styles of houses or building designs, so they tend to be uniform in appearance.",
                ["Real Estate", "We had looked at houses for rent on the West Coast", "Estate Wagon", "The station wagon at Otira", "Housing Estate", "Description of Otira township",],
                "I know that Otira would not be everyone's cup of tea but it was certainly mine. I needed to have time to myself, time to think and work on my book. We joined a Bible Study group in Arthurs Pass and this put us in touch with some very inspirational believers. ",
            ]
},
9: {
    id: 9,
        title: "BOB AND HOPE",
            paragraphs: [
                "My wife and I thought we would try and set up a meeting room in Otira. We decided to rent a second property that we could have meetings in and have a place where visitors could stay for a weekend.",
                "My wife and I attended a Good Friday meeting at The Salvation Army and when doing so, noticed there were several chairs in the opportunity shop for sale that would be exactly what we needed for our planned meeting room. We put a note on the door saying we would come in on Tuesday to buy them. Sadly, at the Sunday meeting we discovered they had been sold. We were surprised at this, as we had presumed the shop would not have been operating over Easter weekend. I went home that day and I was not at all happy about the chairs being sold. We had needed those chairs as they would have been ideal for our meeting room.",
                "I was sitting in our lounge trying to understand what had happened, when there was a knock on our front door. I found an elderly gentleman standing there. He and his wife had arrived in Otira towing a caravan and they were both concerned by the signs warning that towing was not recommended.",
                "otira.jpg",
                "When we talked, I found their names were Bob and Hope. They were from Gisborne; my old home town and they had also been missionary ministers in Papua New Guinea. He parked on our front lawn overnight and the next day we shared their load between us in getting over the Pass. A few days later we received a postcard from them in appreciation for helping. It showed a preacher standing on a beach with a group of people gathered around him - all he had was a small podium and nothing more. We had been hoping to try and get a church group up and running in Otira, and now it appeared this was something we were not meant to do. ",
            ]
},
10: {
    id: 10,
        title: "FALLING DOWN THE STEPS",
            paragraphs: [
                "After it had been discovered that I needed heart surgery, I was sent to the surgeon who was to carry out the operation. I was told it was going to be major heart surgery as I had an aneurysm on my ascending aorta and this meant that I needed a Bentall procedure. I also had two valves that needed to be replaced with mechanical ones and as well as all this, I needed a pacemaker. I was told that my heart would be stopped for more than three hours and that the operation would be carried out in six to eight weeks' time. After being told this, my wife and I returned to Otira to wait for the phone call.",
                "Three months later I decided to ring the hospital and was amazed to be told that I was not on the waiting list and that I didn't need heart surgery at all. I couldn't quite believe what I was hearing. After all the angiograms, x-rays, scans and appointments with my cardiologist and the surgeon, I was now being told that I obviously had not listened to what was said to me. I explained that my wife who is a registered nurse was with me at the time. I was then told that she too had obviously not been listening to what the surgeon had told us. Of course, it was good to be told that I did not require heart surgery after all but it now meant I had to tell family and friends that there had been a misunderstanding and it now appeared that I didn't require heart surgery. Sadly, I had one or two tell me they believed I was simply looking for pity and had never needed surgery.",
                "I was at a loss to understand what had happened. I had been told that I had an aneurysm on my ascending aorta which could burst at any time, rendering me a cardiac cripple or worse, but now I was being told that I had not listened or understood what the surgeon and cardiologist had told me. Then, several days later, I received a phone call from the hospital, from the woman who told me that I had failed to listen or understand what the surgeon had said to me. She now said that she was just a small gear in a large system and that I did require surgery, but she didn't apologize at all for being so wrong.",
                "I was now told that they would get me over to the hospital as soon as possible. In spite of being told that I had an aneurysm on my ascending aorta that could burst at any time, it would be 22 months before I would get the surgery. After I complained about being treated the way I had been, the surgeon refused to do the surgery. The hospital then arranged for me to have surgery at another hospital but they also refused to do the surgery. They wrote to me and said that it would be improper for them to do so.",
                "One night I went out to see if the neighbors were home, when I fell down the front steps and landed face down on the concrete. I lay in the dark, too frightened to move. I was quite certain that I must have hurt myself. I was cold and home alone that night. I could see a red light from the railway yard in the distance. After a while, I decided to try and stand up and to my surprise, I had not been hurt at all. The next day I expected to have a few bruises but there were none. I couldn't quite believe that I had fallen down the steps on to the concrete and not hurt myself at all and I began to wonder if it had meaning for me.",
                "Later that day, I received a phone call from the hospital. Another surgeon had been found who was willing to do the surgery. I sat down on the floor by the phone and cried. I just knew the surgery was going to happen. Although we had been told it was going to be a big operation, neither my wife nor I were too concerned about this. For my part, I was convinced at this stage I was meant to write this book.",
                "The surgery began in the morning and my heart was stopped for more than three hours while it was being performed. It was found that my coronary arteries were normal and this meant they didn't have to touch my legs. I required 15 liters of intravenous fluids and frusemide to bring down my blood pressure. However, I developed a tension hemothorax, blood was leaking around the internal suturing sites and putting pressure on my lungs. This was putting a strain on my breathing. It was decided to cut a hole in my side to drain out the blood and fluid which had pooled in my chest. I then spent three days in a coma.",
                "During my time in hospital, I was awakened in the dark to hear what today I call a sermon. It was a person with a voice that sounded Hungarian. He spoke of how during the war he had known a lot of young men who put their trust in Hitler and other leaders, only to end up in a paddock and being machine gunned down. He then went on to say the only one we can trust is Jesus and He would not let us down.",
                "I suffered very badly from the aftermath of the surgery. Part of my heart and one of my lungs were no longer working the way they should, and I was now going to have to be on Warfarin and learn to live with a pacemaker and a lot of medication. Otira would prove a great place for me to recover from my surgery. It was a place where I could relax and enjoy the pets and scenery. It was not too long before I was back into writing this book. We would go on to live in Otira for close to seven years but after the Village changed hands, we decided it was time to move.",
                "There are many other things that have happened in my life which encouraged me to believe the writing of this book was possible. One such incident was the night I was riding my motor cycle at speed when I hit a bump that caused my headlight to flip up and then I saw some dark forms in the distance. I slammed on my brakes and just managed to stop before colliding with a mob of cattle that were asleep on the road. I can't say whether this was an Act of God, but I do believe if I had not hit that bump when I did, I would most likely have been killed or very badly injured.",
                "Today I believe that only by knowing that God exists, can we come to understand what life is about. I ask this question - Would those who choose to commit suicide have done so if they knew that God exists? Or would those who commit serious crimes have done so if they had known? As I have already said, I feel I'm not and never have been a good person, so why I have been helped in the ways I've shared is a mystery to me. I could have and perhaps should have done far more than I have, but I just haven't had the courage to speak out at times, when maybe I should have. I can only hope that through this book, I can help those who are searching for answers to the questions - Does God exist?",
                "What I believe.",
                "I yours to say that I believe God exist, but now I can say I know that God really does exist.",
                "What I recommend.",
                "Read the Good News Bible, especial the Godspell of Mark.",
                "<u>The trinity</u>",
                "There are three letters that make up the word One, Man, Son, God.",
                "(One of the letters in the word God, is a capital.)",
                "<u>The Baskets</u>",
                "The three days Jesus spent with the five thousand represent the three years of his minister, the twelve baskets, the twelve apostles,",
                "The seven baskets, the seven Church helpers.",
                "The apostle Paul began his minister by being delivered in a basket.",
                "Did you realize that the word Jer<u>usa</u>lem had USA in the middle.",
            ]
}
}

export default Chapters;